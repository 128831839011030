import * as React from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Option,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { Edit } from "@mui/icons-material";
import { GridToolbarDensitySelector } from "@mui/x-data-grid";
import { GridToolbarExport } from "@mui/x-data-grid";
import { GridToolbarFilterButton } from "@mui/x-data-grid";
import { GridToolbarColumnsButton } from "@mui/x-data-grid";
import APIContext from "../../../context/APIContext";
import AuthContext from "../../../context/AuthContext";
import axios from "axios";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import SelectedRecordModal from "./SelectedRecordModal";

const Unassigned = ({ records, fetchRecords, fetchFileScrutiny }) => {
  const [arrayOfRecords, setArrayOfRecords] = React.useState({});

  React.useEffect(() => {
    if (records.length > 0) {
      setArrayOfRecords(records.map((obj) => obj.record));
    }
  }, [records]);

  const columns = [
    { field: "document_number", headerName: "Doc No.", width: 130 },
    { field: "document_type", headerName: "Type", width: 120 },
    { field: "owners_name", headerName: "Owner", width: 150 },
    { field: "country", headerName: "Country", width: 100 },
    { field: "institution", headerName: "Institution", width: 100 },
    { field: "issuing_body", headerName: "Issuer", width: 150 },
    {
      field: "verified",
      headerName: "Verified",
      width: 100,
      renderCell: (params) => {
        return params.row.verified == "Verified" ? (
          <Chip color="success" disabled={false} variant="soft">
            Verified
          </Chip>
        ) : params.row.verified == "Pending" ? (
          <Chip color="primary" disabled={false} variant="soft">
            Pending
          </Chip>
        ) : (
          <Chip color="Rejected" disabled={false} variant="soft">
            Rejected
          </Chip>
        );
      },
    },
    { field: "serial_no", headerName: "Serial", width: 170 },
  ];

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  let [staff, setStaff] = React.useState([]);

  let { API_URL } = React.useContext(APIContext);
  let { authTokens } = React.useContext(AuthContext);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + String(authTokens.access),
    },
  };

  const fetchStaffRecords = async () => {
    await axios
      .get(`${API_URL}api/staff/`, axiosConfig)
      .then((response) => {
        setStaff(response.data.filter((items) => items.is_staff == true));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchStaffRecords();
  }, []);

  const [allocatedStaff, setAllocatedStaff] = React.useState("");

  const handleAllocation = async () => {
    // Make a POST request using the Fetch API
    await fetch(`${API_URL}api/scrutiny/`, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + String(authTokens.access),
      },
      body: JSON.stringify({
        staff: allocatedStaff,
        records: rowSelectionModel,
      }),
    })
      .then((response) => {
        fetchRecords();
        fetchFileScrutiny();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />

        <GridToolbarExport
          slotprops={{
            tooltip: { title: "Export data" },
            button: { variant: "outlined" },
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            variant="outlined"
            displayEmpty
            input={<OutlinedInput />}
            onChange={(e) => setAllocatedStaff(e.target.value)}
            value={allocatedStaff}
          >
            <MenuItem disabled value="">
              <em>Select staff..</em>
            </MenuItem>

            {staff.map((staff, i) => (
              <MenuItem key={i} value={staff.username}>
                {staff.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button color="success" onClick={() => handleAllocation()}>
          Assign
        </Button>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setOpenModal(true);
  };

  return (
    <>
      <Sheet
        sx={{ height: 600, width: "100%", mt: 5 }}
        color="primary"
        variant="soft"
      >
        <DataGrid
          onRowClick={handleRowClick}
          density="comfortable"
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          getRowId={(arrayOfRecords) => arrayOfRecords.document_number}
          columns={columns}
          rows={arrayOfRecords}
          slots={{
            toolbar: CustomToolbar,
            showQuickFilter: true,
          }}
        />
      </Sheet>
      <SelectedRecordModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        selectedRow={selectedRow}
      />
    </>
  );
};
export default Unassigned;
