import {
  CloseRounded,
  PlaylistAddCheckCircleRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Snackbar,
  Table,
  Tooltip,
  Typography,
} from "@mui/joy";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import APIContext from "../context/APIContext";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import Flutter from "../components/Flutter";
import Footer from "../components/Footer";
import SocialMedia from "../components/SocialMedia";
import AuthContext from "../context/AuthContext";
import "../hero.css";

const HomePage = ({ setOpenPayments }) => {
  const [open, setOpen] = React.useState(false);
  const [searched, setSearched] = React.useState(false);

  let { API_URL } = useContext(APIContext);
  let { user } = useContext(AuthContext);
  let [value, setValue] = React.useState("");
  let [searchResult, setSearchResult] = React.useState(null);
  let [error, setError] = React.useState(false);

  const handleSearch = async () => {
    try {
      let response = await axios.post(`${API_URL}api/search/`, {
        value,
      });
      let data = response.data[0];
      setSearchResult(data);
    } catch (err) {
      setError(true);
    }
  };

  // update the searchResult and only show the search results after the update
  useEffect(() => {
    if (searchResult !== null) {
      setSearched(true);
    }
  }, [searchResult]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [successful, setSuccessful] = React.useState(false);

  const [showDemo, setShowDemo] = React.useState(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "3rem",
          background: "transparent",

          zIndex: "1000",
          maxWidth: "100vw",
          minHeight: "fit-content",
        }}
      >
        {/*Search bar: And allow search after pressing the enter key*/}
        {!searched && (
          <Box
            sx={{
              marginTop: "-6rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "3rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: matches ? "column" : "row",
                gap: matches ? ".1rem" : "1rem",
                textAlign: "center",
              }}
            >
              <Typography sx={{ color: "white", fontSize: "2em" }}>
                Download Verified
              </Typography>
              <Typography sx={{ color: "rgb(209,134,86)" }}>
                <TypeAnimation
                  preRenderFirstString={true}
                  sequence={[
                    500,
                    " Academic Certificates", // initially rendered starting point
                    800,
                    " Recommendation Letters",
                    800,
                    " Bid Bonds",
                    800,
                    " Bank Guarantees",
                    800,
                    " Contract Award Letters",
                    800,
                    " Project Completion Certificates",
                    800,
                    " Group Membership Cerificate",
                    800,
                    " Insurance Policies",
                    500,
                  ]}
                  speed={30}
                  style={{ fontSize: "2em" }}
                  repeat={Infinity}
                />
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <Input
                placeholder="Document ID OR Serial Number "
                onChange={(e) => {
                  setValue(e.target.value), setError(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                size="lg"
                sx={{
                  maxWidth: matches ? "60vw" : "45vw",
                }}
              />
              <Button type="submit" size="lg" onClick={handleSearch}>
                Validate
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "3rem",
              }}
            >
              {showDemo && (
                <>
                  <Typography sx={{ color: "white", fontSize: "2em" }}>
                    TA20240629-EI2LHS
                  </Typography>
                  <br />
                </>
              )}
              <Box sx={{ display: "flex", gap: "2vw" }}>
                <Chip
                  variant="outlined"
                  color="neutral"
                  size="lg"
                  onClick={() => setShowDemo(!showDemo)}
                >
                  Demo File
                </Chip>

                <Chip
                  variant="outlined"
                  color="neutral"
                  size="lg"
                  disabled={!showDemo}
                  onClick={() => {
                    navigator.clipboard.writeText("TA20240629-EI2LHS");
                    alert("TA20240629-EI2LHS copied to clipboard");
                    setShowDemo(false);
                  }}
                >
                  Copy Serial
                </Chip>
              </Box>
            </Box>
          </Box>
        )}

        {/* The table will only appear after the search button has been clicked and fetch request is done */}
        {searched && (
          <Sheet
            sx={{
              height: { xs: "100%", sm: "100%", md: "75vh" },
              overflow: "auto",
              opacity: 1,
              mb: { xs: "2rem", sm: "2rem" },
              mt: { xs: "6rem", sm: "6rem", md: "0rem" },
              ml: { xs: ".5rem", sm: ".5rem" },
              mr: { xs: ".5rem", sm: ".5rem" },
            }}
          >
            <Table
              color="neutral"
              variant="soft"
              stripe="odd"
              size="sm"
              overflow="scroll"
              stickyFooter
              stickyHeader
              hoverRow
              sx={{
                border: "none",
                borderRadius: "10px",
                maxWidth: 600,
                margin: 0,
                opacity: 0.8,
                p: 1,
              }}
              align="left"
            >
              <thead>
                <tr>
                  <th></th>

                  <th>
                    <Tooltip
                      title="Close"
                      sx={{ right: 0, position: "absolute", top: 3 }}
                    >
                      <IconButton
                        size="sm"
                        color="danger"
                        variant="solid"
                        onClick={() => setSearched(false)}
                      >
                        <CloseRounded />
                      </IconButton>
                    </Tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Typography
                      level="body-md"
                      color={
                        searchResult.verified === "Verified"
                          ? "success"
                          : searchResult.verified === "Pending"
                          ? "primary"
                          : "danger"
                      }
                    >
                      {searchResult.verified}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      level="body-md"
                      color={
                        searchResult.verified === "Verified"
                          ? "success"
                          : searchResult.verified === "Pending"
                          ? "primary"
                          : "danger"
                      }
                    >
                      {searchResult.serial_no}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>Institution’s Country</td>
                  <td>{searchResult.country}</td>
                </tr>
                <tr>
                  <td>Document Type:</td>
                  <td>{searchResult.document_type}</td>
                </tr>
                <tr>
                  <td>Document Identification Number:</td>
                  <td>{searchResult.document_number}</td>
                </tr>
                <tr>
                  <td>Date of Issue:</td>
                  <td>{searchResult.date_of_issue}</td>
                </tr>
                {searchResult.witness !== null && (
                  <>
                    <tr>
                      <td colSpan={2}>
                        <Typography color="primary" level="body-md">
                          TRUE COPY OF THE ORIGINAL WAS ATTESTED BY
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <td>Name:</td>
                      <td>{searchResult.witness_name}</td>
                    </tr>
                    <tr>
                      <td>Profession:</td>
                      <td>{searchResult.witness_profession}</td>
                    </tr>
                    <tr>
                      <td>Practicing License Number:</td>
                      <td>{searchResult.witness_license_no}</td>
                    </tr>
                    <tr>
                      <td>Phone Number:</td>
                      <td>{searchResult.witness_phone_no}</td>
                    </tr>
                    <tr>
                      <td>Email Address:</td>
                      <td>{searchResult.witness_email}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td colSpan={2}>
                    <Typography color="primary" level="body-md">
                      DOCUMENT OWNER’S DETAILS
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>Owner’s Full Name</td>
                  <td>{searchResult.owners_name}</td>
                </tr>
                <tr>
                  <td>Owner’s Phone Number:</td>
                  <td>{searchResult.owners_phone_number}</td>
                </tr>
                <tr>
                  <td>Owners Email Address:</td>
                  <td>{searchResult.owners_mail_address}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Typography color="primary" level="body-md">
                      ISSUING BODY/INSTITUTION’S DETAILS
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>Type:</td>
                  <td>{searchResult.institution}</td>
                </tr>
                <tr>
                  <td>Name:</td>
                  <td>{searchResult.issuing_body}</td>
                </tr>
                <tr>
                  <td>Email Address:</td>
                  <td>{searchResult.email_of_issuing_body}</td>
                </tr>
                <tr>
                  <td>Phone Number:</td>
                  <td>{searchResult.phone_of_issuing_body}</td>
                </tr>
                <tr>
                  <td>Institution’s Country</td>
                  <td>{searchResult.country}</td>
                </tr>
              </tbody>
              {searchResult.verified === "Verified" && (
                <tfoot>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <Button
                        onClick={() => {
                          setOpen(true);
                          setOpenPayments(true);
                        }}
                      >
                        Download File
                      </Button>
                    </td>
                  </tr>
                </tfoot>
              )}
            </Table>
          </Sheet>
        )}

        {/* Error message if there is an error in the request */}
        {error && (
          <Card color="danger" invertedColors variant="soft">
            <Typography sx={{ flex: "" }} level="title-md">
              {value} does not exist in our records. click{" "}
              <Link to={user.username === "guest" ? "/login" : "dashboard"}>
                Here
              </Link>{" "}
              to Submit for Verification.
            </Typography>
          </Card>
        )}
      </Box>
      {/*Pop up modal to display the search results*/}

      <Modal open={open}>
        <ModalDialog layout="fullscreen">
          <ModalClose
            onClick={() => {
              setOpen(false);
              setOpenPayments(false);
            }}
          />
          <Sheet
            sx={{
              minWidth: "fit-content",
              borderRadius: "md",
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              maxWidth: "fit-content",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            variant="soft"
            color="primary"
          >
            <Flutter
              record={searchResult?.document_number}
              setSuccessful={setSuccessful}
              setOpenPayments={setOpenPayments}
              setOpen={setOpen}
            />
          </Sheet>
        </ModalDialog>
      </Modal>

      <Snackbar
        variant="soft"
        color="success"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        startDecorator={<PlaylistAddCheckCircleRounded />}
        endDecorator={
          <Button
            onClick={() => setSuccessful(false)}
            size="sm"
            variant="soft"
            color="success"
          >
            Dismiss
          </Button>
        }
        open={successful}
        onClose={() => {
          setSuccessful(false);
        }}
      >
        Successful. Please check your email.
      </Snackbar>

      {!matches && <SocialMedia />}

      <Footer />
    </>
  );
};

export default HomePage;
