import { Box } from "@mui/joy";
import "../hero.css"
import React from 'react'


const BufferPage = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        background:
                    "url(https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2)",
                  backgroundSize: "cover",
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
                  
      }}
    >
      <div className="spinner"></div>

    </Box>
  );
};

export default BufferPage;
