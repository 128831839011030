/* eslint-disable react/no-unescaped-entities */
import { Sheet, Typography } from "@mui/joy";
import Footer from "../components/Footer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from 'react'


const AboutUsPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Sheet
        sx={{
          p: 4,
          // opacity: 0.8,
          maxWidth: 1000,
          background: matches
            ? "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0) 100%, rgba(0,212,255,1) 100%)"
            : "linear-gradient(338deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0) 100%, rgba(0,212,255,1) 100%);",
          mt: 12,
          textAlign: "justify",
          mb: matches ? 30 : 20,
        }}
      >
        <Typography level="h2" sx={{ mb: 5, color: "white" }}>
          About Us
        </Typography>
        <Typography level="body-lg" sx={{ mb: 5, color: "white" }}>
          We take pride in helping individuals and businesses ensure the
          authenticity of their important documents. We are a trusted and
          reliable source for all your copy verification needs. Our team of
          highly skilled forensic document examiners, data analysts,
          Advocates/Barristers and private investigators located around the
          globe, are dedicated to providing second-person verification services
          for clients all over the world.
          <br />
          <br />
          <br />
          Founded in Kenya, Copy Validator is a legal Entity that now serves at
          a global scale in anglophone countries, our goal is to provide
          reliable and accurate document authentication services that protect
          our clients from any potential fraud or tampering. We understand the
          importance of having trustworthy documents, and we are committed to
          using our expertise and technology to verify the authenticity of your
          Certified copies.
          <br />
          <br />
          <br />
          Our team works tirelessly behind the scenes to ensure that every
          document is thoroughly examined and validated. With our years of
          experience and dedication to accuracy, you can trust us to be your
          go-to source for true copy verification. Let us help you protect your
          important documents today with Copy Validator or even secure an
          opportunity with confidence.
          <br />
          <br />
          <br />
          We understand the importance of accurate and verified copies in legal,
          financial, and personal matters. That's why we developed a streamlined
          process to provide second-person verification that is essential for
          any official document. At Copy Validator, we take pride in our
          attention to detail and commitment to excellence.
        </Typography>
      </Sheet>
      <Footer />
    </>
  );
};

export default AboutUsPage;
