import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useNavigate } from "react-router-dom";
import SocialMedia from "./SocialMedia";

const Footer = () => {
  let navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [openCountries, setOpenCountries] = React.useState(false);
  return (
    <>
      <Grid
        container
        sx={{
          position: "absolute",
          bottom: "0%",
          left: "0%",
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
          width: "98vw",
          color: "#FFF",
        }}
      >
        <Grid xs={7} sm={7} md={7}>
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
            }}
            divider={<Divider orientation="vertical" />}
            spacing={{ xs: 0, sm: 1, md: 2 }}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: "1rem",
            }}
          >
            <Link
              color="inherit"
              onClick={() => window.open("https://blog.copyvalidator.com/")}
            >
              Blog
            </Link>
            <Link
              color="inherit"
              onClick={() =>
                window.open("https://blog.copyvalidator.com/features/")
              }
            >
              Features
            </Link>
            {location.pathname !== "/" && (
              <Link color="inherit" onClick={() => navigate("/")}>
                Home
              </Link>
            )}

            <Link color="inherit" onClick={() => setOpenCountries(true)}>
              Countries
            </Link>
            <Link
              color="inherit"
              onClick={() =>
                window.open("https://blog.copyvalidator.com/affiliate-home/")
              }
            >
              Refferal Program
            </Link>
          </Stack>
        </Grid>

        <Grid sm={5} xs={5} md={5}>
          <Box>
            <Typography variant="body2" color="text.secondary" align="right">
              Copy Validator®
            </Typography>
            <Typography
              sx={{ textAlign: "right" }}
              variant="body2"
              color="text.secondary"
              align="center"
            >
              {"©"}

              {new Date().getFullYear()}
              {" All rights reserved."}
            </Typography>
          </Box>
        </Grid>

        {matches && (
          <Grid xs={12}>
            <SocialMedia />
          </Grid>
        )}
      </Grid>

      <Modal
        open={openCountries}
        onClose={() => setOpenCountries(false)}
        sx={matches ? {} : { maxHeight: 500, marginTop: "8rem" }}
      >
        <ModalDialog>
          <ModalClose />
          <DialogTitle>We serve the following countries</DialogTitle>
          <DialogContent>
            {africanCountries.map((country, index) => (
              <Typography key={index}>{country}</Typography>
            ))}
            <div>
              This is a modal dialog. Press <code>esc</code> to close it.
            </div>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default Footer;

const africanCountries = [
  "Angola",
  "Botswana",
  "Cameroon",
  "Egypt",
  "Eritrea",
  "Eswatini (the Kingdom of)",
  "Ethiopia",
  "The Gambia",
  "Ghana",
  "Ivory Coast",
  "Kenya",
  "Lesotho",
  "Liberia",
  "Libya",
  "Malawi",
  "Mauritius",
  "Mozambique",
  "Namibia",
  "Nigeria",
  "Republic of South Sudan",
  "Rwanda",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Somalia",
  "South Africa",
  "Sudan",
  "Uganda",
  "United Republic of Tanzania",
  "Zambia",
  "Zimbabwe",
];
