/* eslint-disable react/prop-types */
import { CloseRounded } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Sheet,
  Table,
  Tooltip,
  Typography
} from "@mui/joy";
import axios from "axios";
import React, { useContext, useState } from "react";
import APIContext from "../../context/APIContext";
import AuthContext from "../../context/AuthContext";
import EditRecord from "./EditRecord";

// eslint-disable-next-line react/prop-types
const SelectedRecord = ({
  record,
  setOpenRecord,
  fetchRecords,
  openRecord,
}) => {
  let { authTokens } = useContext(AuthContext);
  const { API_URL } = useContext(APIContext);

  const [editRecord, setEditRecord] = useState(false);
  const [openDeleted, setOpenDeleted] = useState(false);

  const deleteRecord = () => {
    axios
      .delete(`${API_URL}api/upload/`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + String(authTokens.access),
        },
        data: record,
      })
      .then((response) => {
        console.log(response);
        setOpenDeleted(true);
        setOpenRecord(false);
        fetchRecords();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {record !== undefined &&
        (editRecord ? (
          <EditRecord
            record={record}
            setOpenRecord={setOpenRecord}
            openRecord={openRecord}
            setEditRecord={setEditRecord}
            fetchRecords={fetchRecords}            
          />
        ) : (
          <>
            <Sheet
              sx={{
                height: { xs: "100%", sm: "100%", md: "75vh" },
                overflow: "auto",
                opacity: 1,
                mb: { xs: "2rem", sm: "2rem" },
                mt: { xs: "6rem", sm: "6rem", md: "0rem" },
                ml: { xs: ".5rem", sm: ".5rem" },
                mr: { xs: ".5rem", sm: ".5rem" },
              }}
            >
              <Table
                color="neutral"
                variant="soft"
                stripe="odd"
                size="sm"
                overflow="scroll"
                stickyFooter
                stickyHeader
                hoverRow
                sx={{
                  border: "none",
                  borderRadius: "10px",
                  maxWidth: { xs: 600, sm: 600, md: 800 },
                  margin: 0,
                  opacity: 0.8,
                  p: 1,
                }}
                align="left"
              >
                <thead>
                  <tr>
                    <th>
                      <Typography
                        level="body-md"
                        color={
                          record.verified === "Verified"
                            ? "success"
                            : record.verified === "Pending"
                            ? "primary"
                            : "danger"
                        }
                      >
                        {record.verified} : {record.serial_no}
                      </Typography>
                    </th>

                    <th>
                      <Tooltip
                        title="Close"
                        sx={{ right: 0, position: "absolute", top: 3 }}
                      >
                        <IconButton
                          onClick={() => setOpenRecord(false)}
                          size="sm"
                          color="neutral"
                          variant="solid"
                        >
                          <CloseRounded />
                        </IconButton>
                      </Tooltip>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Document Type:</td>
                    <td>{record.document_type}</td>
                  </tr>
                  <tr>
                    <td>Document Identification Number:</td>
                    <td>{record.document_number}</td>
                  </tr>
                  <tr>
                    <td>Date of Issue:</td>
                    <td>{record.date_of_issue}</td>
                  </tr>
                  {record.witness !== null && (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <Typography color="primary" level="body-md">
                            TRUE COPY OF THE ORIGINAL ATTESTED BY
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>Witness’ Name:</td>
                        <td>{record.witness_name}</td>
                      </tr>
                      <tr>
                        <td>Witness’ Profession</td>
                        <td>{record.witness_profession}</td>
                      </tr>
                      <tr>
                        <td>Witness’ Practicing License Number:</td>
                        <td>{record.witness_license_no}</td>
                      </tr>
                      <tr>
                        <td>Witness’ Phone Number:</td>
                        <td>{record.witness_phone_no}</td>
                      </tr>
                      <tr>
                        <td>Witness’ Email Address:</td>
                        <td>{record.witness_email}</td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td colSpan={2}>
                      <Typography color="primary" level="body-md">
                        DOCUMENT OWNER’S DETAILS
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>Owner’s Full Name</td>
                    <td>{record.owners_name}</td>
                  </tr>
                  <tr>
                    <td>Owner’s Phone Number:</td>
                    <td>{record.owners_phone_number}</td>
                  </tr>
                  <tr>
                    <td>Owners Email Address:</td>
                    <td>{record.owners_mail_address}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <Typography color="primary" level="body-md">
                        ISSUING BODY
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>Type:</td>
                    <td>{record.institution}</td>
                  </tr>
                  <tr>
                    <td>Name:</td>
                    <td>{record.issuing_body}</td>
                  </tr>
                  <tr>
                    <td>Email Address:</td>
                    <td>{record.email_of_issuing_body}</td>
                  </tr>
                  <tr>
                    <td>Phone Number:</td>
                    <td>{record.phone_of_issuing_body}</td>
                  </tr>
                  <tr>
                    <td>Institution’s Country</td>
                    <td>{record.country}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <Button
                        color="danger"
                        variant="solid"
                        onClick={() => deleteRecord()}
                      >
                        Delete Record
                      </Button>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        color="primary"
                        onClick={() => setEditRecord(true)}
                      >
                        Update Record
                      </Button>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Sheet>
          </>
        ))}
    </>
  );
};

export default SelectedRecord;
