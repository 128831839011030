import {
  HourglassTopTwoTone,
  PendingActionsRounded,
  RuleFolderTwoTone,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import APIContext from "../../../context/APIContext";
import AuthContext from "../../../context/AuthContext";
import FilterOptions from "../FilterOptions";
import StaffRecordsList from "./StaffRecordList";
import SelectedRecord from "./SelectedRecord";

const StaffPage = () => {
  const [records, setRecords] = useState([]);

  const [clickedRow, setClickedRow] = useState(undefined);
  const [openRecord, setOpenRecord] = useState(false);
  const [addRecord, setAddRecord] = useState(false);

  const handleViewRecord = (row) => {
    setClickedRow(row);
    setOpenRecord(true);
  };

  let { API_URL } = useContext(APIContext);
  let { authTokens } = useContext(AuthContext);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + String(authTokens.access),
    },
  };

  const fetchRecords = async () => {
    await axios
      .get(`${API_URL}api/scrutiny/`, axiosConfig)
      .then((response) => {
        setRecords(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [arrayOfRecords, setArrayOfRecords] = useState([]);

  useEffect(() => {
    fetchRecords();
  }, []);

  const [underScrutiny, setUnderScrutiny] = useState({});
  const [rejected, setRejected] = useState({});
  const [accepted, setAccepted] = useState({});

  useEffect(() => {
    setArrayOfRecords(records.map((obj) => obj.record));
    setRejected(
      records
        .filter((files) => files.verified === "Rejected")
        .map((obj) => obj.record)
    );
    setUnderScrutiny(
      records
        .filter(
          (files) =>
            files.status === "Assigned" && files.verified === "Unverified"
        )
        .map((obj) => obj.record)
    );
    setAccepted(
      records
        .filter((files) => files.verified === "Accepted")
        .map((obj) => obj.record)
    );
  }, [records]);

  const [activeGrid, setActiveGrid] = useState("underScrutiny");

  const [selectedRow, setSelectedRow] = React.useState({});

  return (
    <Sheet sx={{ minHeight: "80vh", width: "100vw", p: 5, pt: 15 }}>
      <Stack>
        <Box></Box>
        <Grid
          container
          spacing={2}
          sx={{
            "--Grid-borderWidth": "1px",
            "& > div": {
              borderRight: "var(--Grid-borderWidth) solid",
              borderColor: "divider",
            },
          }}
        >
          <Grid md={4} sx={{ padding: 3 }}>
            <SelectedRecord
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              fetchRecords={fetchRecords}
              underScrutiny={underScrutiny}
            />
          </Grid>
          <Grid md={8} sx={{ padding: 3 }}>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid md={4}>
                <Card
                  color="primary"
                  invertedColors
                  orientation="horizontal"
                  size="sm"
                  variant="soft"
                  justify="center"
                  sx={{
                    width: "fit-content",
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setActiveGrid("underScrutiny")}
                >
                  <Box>
                    <HourglassTopTwoTone />
                  </Box>
                  <CardContent>
                    <Typography level="title-lg" id="card-description">
                      Documents Under Scrutiny
                    </Typography>
                    <Typography level="body-sm" mb={1}>
                      {underScrutiny?.length} Documents are under review
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid md={4}>
                <Card
                  onClick={() => setActiveGrid("accepted")}
                  color="primary"
                  invertedColors
                  orientation="horizontal"
                  size="sm"
                  variant="soft"
                  justify="center"
                  sx={{
                    width: "fit-content",
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <PendingActionsRounded />
                  </Box>
                  <CardContent>
                    <Typography level="title-lg" id="card-description">
                      Documents Accepted
                    </Typography>
                    <Typography level="body-sm" mb={1}>
                      {accepted?.length} Files are pending for final approval
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid md={4}>
                <Card
                  color="primary"
                  invertedColors
                  orientation="horizontal"
                  size="sm"
                  variant="soft"
                  justify="center"
                  sx={{
                    width: "fit-content",
                    "&:hover": {
                      boxShadow: "md",
                      borderColor: "neutral.outlinedHoverBorder",
                    },
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Box>
                    <RuleFolderTwoTone />
                  </Box>
                  <CardContent>
                    <Typography level="title-lg" id="card-description">
                      Documents Rejected
                    </Typography>
                    <Typography level="body-sm" mb={1}>
                      {rejected?.length} Files did not qualify to be approved
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <StaffRecordsList
              records={
                activeGrid == "underScrutiny" ? underScrutiny : arrayOfRecords
              }
              fetchRecords={fetchRecords}
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
            />
          </Grid>
        </Grid>
      </Stack>
    </Sheet>
  );
};

export default StaffPage;
