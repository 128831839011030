import { Typography } from "@mui/joy";
import error from "../assets/error.svg"
import React from 'react'

const ErrorPage = () => {
 

  return (
    <div id="error-page">
      <img src={error}/>
      
      <Typography level="body-md" sx={{color:"white"}}>Sorry, an unexpected error has occurred.</Typography>
      
    </div>
  );
};

export default ErrorPage;
