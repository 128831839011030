import { Box } from "@mui/material";
import React, { useCallback } from "react";
import Particles from "react-particles";
import { Route, Routes } from "react-router-dom";
import { loadSlim } from "tsparticles-slim";
import NavigationBar from "./components/NavigationBar";
import { APIProvider } from "./context/APIContext";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoutes from "./context/PrivateRoutes";
import PublicLogin from "./context/PublicLogin";
import "./hero.css";
import AboutUsPage from "./pages/AboutUsPage";
import ChangePassword from "./pages/ChangePassword";
import DashboardPage from "./pages/DashboardPage";
import ErrorPage from "./pages/ErrorPage";
import FileActionPage from "./pages/FileActionPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RequestResetEmail from "./pages/RequestResetEmail";
import SignupPage from "./pages/SignupPage";
import WhyUsPage from "./pages/WhyUsPage";

function App() {
  const particlesInit = useCallback(async (engine) => {
    //console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  let [openPayments, setOpenPayments] = React.useState(false)

  

  return (
    <>
      <APIProvider>
        <AuthProvider>
          <>
          {openPayments == false && <NavigationBar />}
            
            <>
              <Box
                sx={{
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "url(https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2)",
                  backgroundSize: "cover",
                  minHeight: "100vh",
                  maxWidth: "100vw",
                  position: "relative",
                  mt: "-5rem",
                }}
              >
                <Particles
                  id="tsparticles"
                  init={particlesInit}
                  options={{
                    background: {
                      color: {
                        value: "transparent",
                      },
                    },
                    fullScreen: true,

                    zIndex: 0,
                    fpsLimit: 120,
                    interactivity: {
                      events: {
                        onClick: {
                          //enable: true,
                          //mode: "push",
                        },
                        onHover: {
                          enable: true,
                          mode: "repulse",
                        },
                        resize: true,
                      },
                      modes: {
                        push: {
                          quantity: 4,
                        },
                        repulse: {
                          distance: 200,
                          duration: 0.4,
                        },
                      },
                    },
                    particles: {
                      color: {
                        value: "#FFF",
                      },
                      links: {
                        color: "#FFF",
                        distance: 150,
                        enable: true,
                        opacity: 0.3,
                        width: 1,
                      },
                      move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                          default: "bounce",
                        },
                        random: false,
                        speed: 1,
                        straight: false,
                      },
                      number: {
                        density: {
                          enable: true,
                          area: 1000,
                        },
                        value: 50,
                      },
                      opacity: {
                        value: 0.5,
                      },
                      shape: {
                        type: "circle",
                      },
                      size: {
                        value: { min: 1, max: 5 },
                      },
                    },
                    detectRetina: true,
                  }}
                />
                <Routes>
                  <Route path="/" exact element={<HomePage  setOpenPayments={setOpenPayments}/>} />
                  <Route element={<PrivateRoutes />}>
                    <Route
                      path="/dashboard"
                      exact
                      element={<DashboardPage/>}

                    />
                    <Route
                      path="/staff"
                      exact
                      element={<FileActionPage/>}
                    />
                    
                   
                  </Route>
                  <Route path="/about-us" exact element={<AboutUsPage />} />
                  <Route element={<PublicLogin />}>
                    <Route
                      path={"/reset-password/:id/:id"}
                      element={<ChangePassword />}
                    />
                    <Route
                      path={"/request-reset-url"}
                      element={<RequestResetEmail />}
                    />
                    <Route
                      path="/create-account"
                      exact
                      element={<SignupPage />}
                    />
                    <Route path="/login" exact element={<LoginPage />} />
                  </Route>
                  <Route path="/why-us" element={<WhyUsPage />} />
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </Box>
            </>
          </>
        </AuthProvider>
      </APIProvider>
    </>
  );
}

export default App;
