import {
  AddRounded,
  CancelRounded,
  PendingOutlined,
  PlaylistAddCheckCircleRounded,
  VerifiedRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Typography,
} from "@mui/joy";
import { Icon } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NewRecord from "../components/Dashboard/NewRecord";
import SelectedRecord from "../components/Dashboard/SelectedRecord";
import APIContext from "../context/APIContext";
import AuthContext from "../context/AuthContext";

const DashboardPage = () => {
  const [records, setRecords] = useState([]);

  const [clickedRow, setClickedRow] = useState(undefined);
  const [openRecord, setOpenRecord] = useState(false);
  const [addRecord, setAddRecord] = useState(false);

  const handleViewRecord = (row) => {
    setClickedRow(row);
    setOpenRecord(true);
    console.log(clickedRow);
  };

  let { API_URL } = useContext(APIContext);
  let { authTokens } = useContext(AuthContext);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + String(authTokens.access),
    },
  };

  const fetchRecords = async () => {
    await axios
      .get(`${API_URL}api/upload/`, axiosConfig)
      .then((response) => {
        console.log(response.data);
        setRecords(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  return (
    <>
      {addRecord ? (
        <NewRecord
          addRecord={addRecord}
          setAddRecord={setAddRecord}
          fetchRecords={fetchRecords}
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
        />
      ) : openRecord ? (
        <SelectedRecord
          fetchRecords={fetchRecords}
          openRecord={openRecord}
          record={clickedRow}
          setOpenRecord={setOpenRecord}
        />
      ) : records.length < 1 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <Typography level="h3" sx={{ color: "white", textAlign: "center" }}>
            You have not uploaded any document.
          </Typography>

          <Button
            endDecorator={<AddRounded />}
            onClick={() => setAddRecord(true)}
            color="success"
          >
            Add Record
          </Button>
        </Box>
      ) : (
        <>
          <Grid
            container
            spacing={4}
           
            sx={{
              maxHeight: "85vh",
              maxWidth: { sm: "100vw", xs: "100vw", md: "90vw" },
              overflow: "auto",
              display: "flex",
              position: "absolute",
              top: "0%",
              alignItems: "center",
              mt: { xs: 0, sm: 0, md: 15 },
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pr: 8,
                }}
              >
                <Typography
                  level="h2"
                  sx={{
                    color: "white",
                  }}
                >
                  Dashboard
                </Typography>

                <Button
                  endDecorator={<AddRounded />}
                  onClick={() => setAddRecord(true)}
                  color="success"
                >
                  New Record
                </Button>
              </Box>
            </Grid>
            {records.map((record, index) => (
              <Grid xs={12} sm={6} md={6} lg={4} key={index}>
                <Card
                  onClick={() => handleViewRecord(record)}
                  color={
                    record.verified === "Verified"
                      ? "success"
                      : record.verified === "Pending"
                      ? "primary"
                      : "warning"
                  }
                  invertedColors
                  size="sm"
                  variant="soft"
                  sx={{
                    width: 320,
                    boxShadow: "md",
                    "&:hover": {
                      boxShadow: "lg",
                    },
                  }}
                >
                  <div>
                    <Typography level="title-lg">
                      {record.owners_name}
                    </Typography>
                    <Typography level="body-sm">
                      {record.document_type} issued on <br />
                      {record.date_of_issue}
                    </Typography>
                    <Icon
                      variant="plain"
                      color="neutral"
                      sx={{
                        position: "absolute",
                        top: "0.5rem",
                        right: "0.5rem",
                        pb: 1,
                      }}
                    >
                      {record.verified === "Verified" ? (
                        <VerifiedRounded />
                      ) : record.verified === "Pending" ? (
                        <PendingOutlined />
                      ) : (
                        <CancelRounded />
                      )}
                    </Icon>
                  </div>
                  <CardContent orientation="horizontal">
                    <div>
                      <Typography level="body-xs">Issued by:</Typography>
                      <Typography fontSize="lg" fontWeight="lg">
                        {record.issuing_body} : {record.country}
                      </Typography>
                    </div>
                    <Button
                      variant="soft"
                      size="md"
                      color={
                        record.verified === "Verified"
                          ? "success"
                          : record.verified === "Pending"
                          ? "primary"
                          : "warning"
                      }
                      sx={{
                        ml: "auto",
                        alignSelf: "center",
                        fontWeight: 600,
                      }}
                    >
                      {record.verified === "Verified"
                        ? "Verified"
                        : record.verified === "Pending"
                        ? "Pending"
                        : "Rejected"}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Snackbar
            variant="soft"
            color="success"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            startDecorator={<PlaylistAddCheckCircleRounded />}
            endDecorator={
              <Button
                onClick={() => setOpenSnackbar(false)}
                size="sm"
                variant="soft"
                color="success"
              >
                Dismiss
              </Button>
            }
            open={openSnackbar}
            onClose={() => {
              setOpenSnackbar(false);
            }}
          >
            Record was submitted successfully
          </Snackbar>
        </>
      )}
    </>
  );
};

export default DashboardPage;
