import * as React from "react";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails, {
  accordionDetailsClasses,
} from "@mui/joy/AccordionDetails";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Table,
  Typography,
} from "@mui/joy";
import AuthContext from "../../../context/AuthContext";
import APIContext from "../../../context/APIContext";
import { PreviewRounded } from "@mui/icons-material";

const SelectedRecordModal = ({ setOpenModal, openModal, selectedRow }) => {
  const [index, setIndex] = React.useState(0);
  const { API_URL } = React.useContext(APIContext);

  return (
    <Modal
      open={openModal}
      onClose={(_event, reason) => {
        setOpenModal(false);
      }}
    >
      <ModalDialog
        color="neutral"
        variant="soft"
        size="md"
        sx={{ maxWidth: 650, width: "100%" }}
      >
        <ModalClose />
        <AccordionGroup
          variant="plain"
          sx={{
            borderRadius: "lg",
            [`& .${accordionSummaryClasses.button}:hover`]: {
              bgcolor: "transparent",
            },
            [`& .${accordionDetailsClasses.content}`]: {
              boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
              [`&.${accordionDetailsClasses.expanded}`]: {
                paddingBlock: "0.75rem",
              },
            },
          }}
        >
          <Accordion
            expanded={index === 1}
            onChange={(event, expanded) => {
              setIndex(expanded ? 1 : null);
            }}
          >
            <AccordionSummary>Owner's Details</AccordionSummary>
            <AccordionDetails variant="soft">
              <Table size="small" stripe="odd">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{selectedRow.owners_name}</td>
                  </tr>
                  <tr>
                    <td>Document No.</td>
                    <td>{selectedRow.document_number}</td>
                  </tr>
                  <tr>
                    <td>Dcument Type</td>
                    <td>{selectedRow.document_type}</td>
                  </tr>
                  <tr>
                    <td>Date of Issue</td>
                    <td>{selectedRow.date_of_issue}</td>
                  </tr>
                  <tr>
                    <td>Date of Issue</td>
                    <td>{selectedRow.expiry_date}</td>
                  </tr>
                  <tr>
                    <td>Phone No.</td>
                    <td>{selectedRow.owners_phone_number}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{selectedRow.owners_mail_address}</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{selectedRow.country}</td>
                  </tr>
                  <tr>
                    <td>Document</td>
                    <td>
                      <IconButton
                        onClick={() =>
                          window.open(
                            `https://copyvalidator.com${selectedRow.document}`,
                            "_blank",
                            "noreferrer"
                          )
                        }
                      >
                        <PreviewRounded />
                      </IconButton>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={index === 2}
            onChange={(event, expanded) => {
              setIndex(expanded ? 2 : null);
            }}
          >
            <AccordionSummary>Institution's Details</AccordionSummary>
            <AccordionDetails variant="soft">
              <Table size="small" stripe="odd">
                <tbody>
                  <tr>
                    <td>Institution</td>
                    <td>{selectedRow.institution}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{selectedRow.issuing_body}</td>
                  </tr>
                  <tr>
                    <td>Phone No.</td>
                    <td>{selectedRow.phone_of_issuing_body}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{selectedRow.email_of_issuing_body}</td>
                  </tr>
                </tbody>
              </Table>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={index === 3}
            onChange={(event, expanded) => {
              setIndex(expanded ? 3 : null);
            }}
          >
            <AccordionSummary>Witness</AccordionSummary>
            <AccordionDetails variant="soft">
              <Table size="small" stripe="odd">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{selectedRow.witness_name}</td>
                  </tr>
                  <tr>
                    <td>License No.</td>
                    <td>{selectedRow.witness_license_no}</td>
                  </tr>
                  <tr>
                    <td>Profession</td>
                    <td>{selectedRow.witness_profession}</td>
                  </tr>
                  <tr>
                    <td>Phone No.</td>
                    <td>{selectedRow.witness_phone_no}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{selectedRow.witness_email}</td>
                  </tr>
                </tbody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </ModalDialog>
    </Modal>
  );
};

export default SelectedRecordModal;
