import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import List from "@mui/joy/List";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Table,
} from "@mui/joy";
import APIContext from "../../../context/APIContext";
import AuthContext from "../../../context/AuthContext";
import { PreviewRounded } from "@mui/icons-material";

const SelectedRecord = ({
  records,
  fetchRecords,
  setSelectedRow,
  underScrutiny,
  selectedRow,
}) => {
  const [checkbox1Checked, setCheckbox1Checked] = React.useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = React.useState(false);

  const [status, setStatus] = React.useState("Rejected");

  const handleCheckbox1Change = (event) => {
    setCheckbox1Checked(event.target.checked);
    setCheckbox2Checked(false); // Uncheck checkbox 2 when checkbox 1 is checked
  };

  const handleCheckbox2Change = (event) => {
    setCheckbox2Checked(event.target.checked);
    setCheckbox1Checked(false); // Uncheck checkbox 1 when checkbox 2 is checked
  };

  React.useEffect(() => {
    if (checkbox1Checked === true) {
      setStatus("Accepted");
    } else {
      setStatus("Rejected");
    }
    console.log(status);
  }, [checkbox1Checked, checkbox2Checked]);

  let { user, authTokens } = React.useContext(AuthContext);
  let { API_URL } = React.useContext(APIContext);

  const handleSubmitFinding = async () => {
    // Make a POST request using the Fetch API
    await fetch(`${API_URL}api/scrutiny/`, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + String(authTokens.access),
      },
      body: JSON.stringify({
        record: selectedRow?.document_number,
        status: status,
        staff: user,
      }),
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    fetchRecords();
  };

  const [isScrutinized, setIsScrutinized] = React.useState(false);

  React.useEffect(() => {
    // Function to check if a record with a specific document number exists
    if (selectedRow.document_number !== undefined) {
      setIsScrutinized(
        underScrutiny.some(
          (document) => document.document_number === selectedRow.document_number
        )
      );
    }
  }, [selectedRow]);
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 4,
        height: "88vh",
        overflow: "auto",
      }}
    >
      <List
        variant="soft"
        color="primary"
        sx={{
          minWidth: 240,
          borderRadius: "sm",
        }}
      >
        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography level="body-md">Owner's Details</Typography>
          <Table
            size="md"
            stripe="odd"
            borderAxis="xBetween"
            variant="outlined"
            sx={{ borderRadius: "10px" }}
          >
            <tbody style={{ borderRadius: "10px" }}>
              <tr>
                <td>Name</td>
                <td>{selectedRow.owners_name}</td>
              </tr>
              <tr>
                <td>Document No.</td>
                <td>{selectedRow.document_number}</td>
              </tr>
              <tr>
                <td>Dcument Type</td>
                <td>{selectedRow.document_type}</td>
              </tr>
              <tr>
                <td>Date of Issue</td>
                <td>{selectedRow.date_of_issue}</td>
              </tr>
              <tr>
                <td>Date of Expiry</td>
                <td>{selectedRow.expiry_date}</td>
              </tr>
              <tr>
                <td>Phone No.</td>
                <td>{selectedRow.owners_phone_number}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{selectedRow.owners_mail_address}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{selectedRow.country}</td>
              </tr>
              <tr>
                <td>Document</td>
                <td>
                  <IconButton
                    onClick={() =>
                      window.open(
                        `https://copyvalidator.com${selectedRow.document}`,
                        "_blank",
                        "noreferrer"
                      )
                    }
                  >
                    <PreviewRounded />
                  </IconButton>
                  
                </td>
              </tr>
            </tbody>
          </Table>
        </ListItem>

        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography level="body-md">Institution's Details</Typography>
          <Table
            size="md"
            stripe="odd"
            borderAxis="xBetween"
            variant="outlined"
            sx={{ borderRadius: "10px" }}
          >
            <tbody style={{ borderRadius: "10px" }}>
              <tr>
                <td>Institution</td>
                <td>{selectedRow.institution}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{selectedRow.issuing_body}</td>
              </tr>
              <tr>
                <td>Phone No.</td>
                <td>{selectedRow.phone_of_issuing_body}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{selectedRow.email_of_issuing_body}</td>
              </tr>
            </tbody>
          </Table>
        </ListItem>

        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography level="body-md">Witness</Typography>
          <Table
            size="md"
            stripe="odd"
            borderAxis="xBetween"
            variant="outlined"
            sx={{ borderRadius: "10px" }}
          >
            <tbody style={{ borderRadius: "10px" }}>
              <tr>
                <td>Name</td>
                <td>{selectedRow.witness_name}</td>
              </tr>
              <tr>
                <td>License No.</td>
                <td>{selectedRow.witness_license_no}</td>
              </tr>
              <tr>
                <td>Profession</td>
                <td>{selectedRow.witness_profession}</td>
              </tr>
              <tr>
                <td>Phone No.</td>
                <td>{selectedRow.witness_phone_no}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{selectedRow.witness_email}</td>
              </tr>
            </tbody>
          </Table>
        </ListItem>

        {isScrutinized && (
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <FormControl color="success">
              <Checkbox
                label="Accepted"
                checked={checkbox1Checked}
                onChange={handleCheckbox1Change}
                disabled={checkbox2Checked} // Disable checkbox 1 if checkbox 2 is checked
              />
              <FormHelperText>Checked and ready for Approval.</FormHelperText>
            </FormControl>
            <FormControl color="danger">
              <Checkbox
                label="Rejected"
                checked={checkbox2Checked}
                onChange={handleCheckbox2Change}
                disabled={checkbox1Checked} // Disable checkbox 2 if checkbox 1 is checked
              />
              <FormHelperText>
                Record does not qualify to be Approved
              </FormHelperText>
            </FormControl>
            <Button
              disabled={checkbox1Checked == false && checkbox2Checked == false}
              onClick={() => handleSubmitFinding()}
            >
              Submit
            </Button>
          </ListItem>
        )}
      </List>
    </Box>
  );
};
export default SelectedRecord;
