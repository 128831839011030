import * as React from "react";
import { DataGrid, GridToolbar, GridToolbarExport } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { Box, Chip, Sheet, Input, Typography, IconButton } from "@mui/joy";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { GridToolbarColumnsButton } from "@mui/x-data-grid";
import { GridToolbarFilterButton } from "@mui/x-data-grid";
import { GridToolbarDensitySelector } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import SelectedRecordModal from "./SelectedRecordModal";

const RecordsList = ({ records }) => {
  const columns = [
    { field: "document_number", headerName: "Doc No.", width: 130 },
    { field: "document_type", headerName: "Type", width: 120 },
    { field: "owners_name", headerName: "Owner", width: 150 },
    { field: "country", headerName: "Country", width: 100 },
    { field: "institution", headerName: "Institution", width: 100 },
    { field: "issuing_body", headerName: "Issuer", width: 150 },
    {
      field: "verified",
      headerName: "Verified",
      width: 100,
      renderCell: (params) => {
        return params.row.verified == "Verified" ? (
          <Chip color="success" disabled={false} variant="soft">
            Verified
          </Chip>
        ) : params.row.verified == "Pending" ? (
          <Chip color="primary" disabled={false} variant="soft">
            Pending
          </Chip>
        ) : (
          <Chip color="danger" disabled={false} variant="soft">
            Rejected
          </Chip>
        );
      },
    },
    { field: "serial_no", headerName: "Serial", width: 170, visible: false },
  ];

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setOpenModal(true);
  };

  return (
    <>
      <Sheet
        sx={{ height: 600, width: "100%", mt: 5 }}
        color="primary"
        variant="soft"
      >
        {records.length > 0 && (
          <DataGrid
            onRowClick={handleRowClick}
            //checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            onFilterModelChange={(newValue) => {
              // And here I want to get that data to be able
              // to pas it to backend request or somehow have acces
              // to it in fucntion App()
              //console.log(newValue);
              // setDates({
              //   startDate: newValue[0],
              //   endDate: newValue[1].toISOString()
              // });
            }}
            rowSelectionModel={rowSelectionModel}
            getRowId={(records) => records.document_number}
            columns={columns}
            rows={records}
            slots={{ toolbar: GridToolbar, showQuickFilter: true }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        )}
      </Sheet>
      <SelectedRecordModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        selectedRow={selectedRow}
      />
    </>
  );
};
export default RecordsList;
