import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import { Button, Table } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import APIContext from "../../../context/APIContext";
import AuthContext from "../../../context/AuthContext";
import axios from "axios";
import { CardOverflow, Stack } from "@mui/joy";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";

const SidePanel = ({ records }) => {
  let [staff, setStaff] = React.useState([]);
  let [users, setUsers] = React.useState([]);

  let { API_URL } = React.useContext(APIContext);
  let { authTokens } = React.useContext(AuthContext);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + String(authTokens.access),
    },
  };

  const fetchStaffRecords = async () => {
    await axios
      .get(`${API_URL}api/staff/`, axiosConfig)
      .then((response) => {
        setStaff(response.data.filter((items) => items.is_staff == true));
        setUsers(response.data.filter((items) => items.is_staff == false));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchStaffRecords();
  }, []);

  const [newStaff, setNewStaff] = React.useState("");

  const handleAddStaff = async () => {
    await fetch(`${API_URL}api/staff/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + String(authTokens.access),
      },
      body: JSON.stringify(newStaff),
    })
      .then((response) => {
        fetchStaffRecords();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pr: 3,
        }}
      >
        <Typography level="h3" sx={{ textAlign: "center" }}>
          Staff
        </Typography>
        <FormControl sx={{ m: 1 }} size="small">
          <Select
            variant="outlined"
            displayEmpty
            input={<OutlinedInput />}
            onChange={(e) => setNewStaff(e.target.value)}
            value={newStaff}
          >
            <MenuItem disabled value="">
              <em>Select user..</em>
            </MenuItem>

            {users.map((staff, i) => (
              <MenuItem key={i} value={staff.username}>
                {staff.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button disabled={newStaff == ""} onClick={() => handleAddStaff()}>
          Add
        </Button>
      </Box>
      {staff.length > 0 && (
        <Table
          size="md"
          stripe="odd"
          borderAxis="xBetween"
          variant="outlined"
          sx={{ borderRadius: "10px" }}
        >
          <thead>
            <th>Staff</th>
            <th>Assigned</th>
            <th>Pending</th>
            <th>Approved</th>
            <th>Rejected</th>
          </thead>
          <tbody style={{ borderRadius: "10px" }}>
            {staff.map((person, index) => (
              <tr>
                <td>{person.username}</td>
                <td>
                  {
                    records.filter(
                      (obj) => obj.staff.username == person.username
                    )?.length
                  }
                </td>
                <td>
                  {
                    records.filter(
                      (obj) =>
                        obj.staff.username == person.username &&
                        obj.verified == "Unverified"
                    )?.length
                  }
                </td>
                <td>
                  {
                    records.filter(
                      (obj) =>
                        obj.staff.username == person.username &&
                        obj.verified == "Accepted"
                    )?.length
                  }
                </td>
                <td>
                  {
                    records.filter(
                      (obj) =>
                        obj.staff.username == person.username &&
                        obj.verified == "Rejected"
                    )?.length
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

   
    </Stack>
  );
};

export default SidePanel;
