import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Card, FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import {
	Alert,
	Button,
	Chip,
	IconButton
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import APIContext from "../context/APIContext";
import React from 'react'




const ChangePassword = () => {
  let { API_URL } = useContext(APIContext);
  const navigate = useNavigate();
  const location = useLocation();
  let url = location.pathname;
  let reset_uuid = url.substring(
    url.lastIndexOf("64=") + 3,
    url.lastIndexOf("/")
  );
  let reset_token = url.slice(url.lastIndexOf("en=") + 3);

  let defaultData = { password: "", repeatPassword: "" };
  const [values, setValues] = useState(defaultData);
  let [passwordMismatch, setPasswordMismatch] = useState(false);
  let [passwordError, setPasswordError] = useState(false);
  let [fieldValidated, setFieldValidated] = useState({
    password: false,
    repeatPassword: false,
  });

  let [isInvalid, setIsInvalid] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let handleVerification = () => {
    if (values.password.length > 0 && values.password.length < 6) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
      setFieldValidated({ ...fieldValidated, password: true });
    }
    if (values.password !== values.repeatPassword) {
      setPasswordMismatch(true);
    } else {
      setPasswordMismatch(false);
      setFieldValidated({ ...fieldValidated, repeatPassword: true });
    }
  };

  useEffect(() => {
    handleVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${API_URL}api/password-reset-complete`, {
        password: values.password,
        token: reset_token,
        uidb64: reset_uuid,
      });
      navigate("/login", { replace: true });
    } catch (err) {
      setIsInvalid(true);
    }
  };

  return (
    <Card
      variant="soft"
      sx={{
        margin: "auto",
        width: "80%",
        maxWidth: "30em",
        padding: "20px",
        marginTop: "2em",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {isInvalid === true && (
        <Alert
          variant="outlined"
          severity="error"
          sx={{ marginTop: "2em", marginBottom: "2em" }}
        >
          Your reset password link is invalid! Please request for another link.
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel>Enter password:</FormLabel>
          <Input
            error={passwordError}
            onChange={handleChange("password")}
            value={values.password}
            type={values.showPassword ? "text" : "password"}
            sx={{
              "--Input-radius": "10px",
              "--Input-paddingInline": "20px",
              "--Input-decoratorChildHeight": "40px",
            }}
            endDecorator={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
            required
          />
          <FormHelperText>
            {passwordError === true ? "6 Characters Minimum" : null}
          </FormHelperText>
        </FormControl>
        <br />
        <br />
        <FormControl>
          <FormLabel>Repeat password:</FormLabel>
          <Input
            error={passwordMismatch}
            onChange={handleChange("repeatPassword")}
            value={values.repeatPassword}
            type={values.showPassword ? "text" : "password"}
            sx={{
              "--Input-radius": "10px",
              "--Input-paddingInline": "20px",
              "--Input-decoratorChildHeight": "40px",
            }}
            endDecorator={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            }
            required
          />
          <FormHelperText>
            {passwordMismatch === true ? "Password Mismatch" : null}
          </FormHelperText>
        </FormControl>

        <br />
        <div
          style={{
            marginTop: "2em",
            display: "flex",
            justifyContent: "end",
          }}
        >
          {isInvalid === true ? (
            <Chip
              onClick={() => navigate("/request-reset-url")}
              label="New Link?"
              component={Button}
              color="primary"
              variant="outlined"
              sx={{
                "&:hover": {
                  backgroundColor: "#EDEDED",
                  color: "black",
                  border: "1px solid black",
                },
                marginRight: "30%",
              }}
            />
          ) : null}
          <Chip
            type="submit"
            label="Reset Password"
            component={Button}
            disabled={
              values.password.length < 1 ||
              passwordError === true ||
              passwordMismatch === true
                ? true
                : false
            }
            color={isInvalid === false ? "primary" : "secondary"}
            variant="contained"
            sx={{
              "&:hover": {
                backgroundColor: "#EDEDED",
                color: "black",
                border: "1px solid black",
              },
            }}
          />
        </div>
      </form>
    </Card>
  );
};

export default ChangePassword;
