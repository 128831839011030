import { Chip, Sheet } from "@mui/joy";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";

const UnderScrutiny = ({ records }) => {
  const columns = [
    { field: "document_number", headerName: "Doc No.", width: 130 },
    { field: "document_type", headerName: "Type", width: 120 },
    { field: "owners_name", headerName: "Owner", width: 150 },
    { field: "country", headerName: "Country", width: 100 },
    { field: "institution", headerName: "Institution", width: 100 },
    { field: "issuing_body", headerName: "Issuer", width: 150 },
    {
      field: "verified",
      headerName: "Verified",
      width: 100,
      renderCell: (params) => {
        return params.row.verified == "Verified" ? (
          <Chip color="success" disabled={false} variant="soft">
            Verified
          </Chip>
        ) : params.row.verified == "Pending" ? (
          <Chip color="primary" disabled={false} variant="soft">
            Pending
          </Chip>
        ) : (
          <Chip color="Rejected" disabled={false} variant="soft">
            Rejected
          </Chip>
        );
      },
    },
    { field: "serial_no", headerName: "Serial", width: 170 },
  ];

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const filterColumns = ({ field, columns, currentFilters }) => {
    // remove already filtered fields from list of columns
    const filteredFields = currentFilters?.map((item) => item.field);
    return columns
      .filter(
        (colDef) =>
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))
      )
      .map((column) => column.field);
  };

  const getColumnForNewFilter = ({ currentFilters, columns }) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
      .filter(
        (colDef) => colDef.filterable && !filteredFields.includes(colDef.field)
      )
      .find((colDef) => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
  };

  React.useEffect(() => {
    console.log(rowSelectionModel);
  }, [rowSelectionModel]);

  return (
    <Sheet
      sx={{ height: 600, width: "100%", mt: 5 }}
      color="primary"
      variant="soft"
    >
      {records.length > 0 && (
        <DataGrid
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          onFilterModelChange={(newValue) => {
            // And here I want to get that data to be able
            // to pas it to backend request or somehow have acces
            // to it in fucntion App()
            console.log(newValue);
            // setDates({
            //   startDate: newValue[0],
            //   endDate: newValue[1].toISOString()
            // });
          }}
          rowSelectionModel={rowSelectionModel}
          getRowId={(records) => records.document_number}
          columns={columns}
          rows={records}
          slots={{ toolbar: GridToolbar, showQuickFilter: true }}
        />
      )}
    </Sheet>
  );
};
export default UnderScrutiny;
