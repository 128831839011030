import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import React from "react";
import poweredBy from "../assets/flutter.svg";
import APIContext from "../context/APIContext";

export default function Flutter({
  record,
  setSuccessful,
  setOpenPayments,
  setOpen,
}) {
  const [minimumAmount, setMinimumAmount] = React.useState(1);
  const [values, setValues] = React.useState({
    currency: "USD",
    firstName: "",
    lastName: "",
    email: "",
    tx_ref: generateTransactionId(),
    comment: "",
    amount: minimumAmount,
  });

  function generateTransactionId() {
    // Generate a random string
    let randomString = Math.random().toString(36).substr(2, 10); // Random alphanumeric string of length 10

    // Get current timestamp
    let timestamp = new Date().getTime(); // Current timestamp in milliseconds

    // Combine timestamp and random string
    let transactionId = timestamp.toString() + randomString;

    return transactionId;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  React.useEffect(() => {
    // Fetch currency conversion rate when selectedCurrency changes
    const fetchConversionRate = async () => {
      try {
        const response = await axios.get(
          `https://api.exchangerate-api.com/v4/latest/USD`
        );
        const rates = response.data.rates;

        const conversionRate = rates[values.currency];
        setMinimumAmount(conversionRate);
        setValues({ ...values, amount: conversionRate });
      } catch (error) {
        console.error("Error fetching conversion rate:", error);
      }
    };

    fetchConversionRate();
  }, [values.currency]);

  const config = {
    public_key: "FLWPUBK-2bc5d9b3f692fdc66c641fc7c48079ae-X",
    //public_key: "FLWPUBK_TEST-37490653f513871ee1f174496392f30e-X",
    tx_ref: values.tx_ref,
    amount: values.amount,
    currency: values.currency,
    payment_options: "card, account, mpesa",
    customer: {
      email: values.email,
      phone_number: "",
      name: values.firstName + " " + values.lastName,
    },
    customizations: {
      title: "copy validator",
      description: "Payment for a validated copy",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };

  let { API_URL } = React.useContext(APIContext);
  const handleFlutterPayment = useFlutterwave(config);

  const savePayment = async () => {
    try {
      await axios
        .post(`${API_URL}api/download/`, {
          values,
        })
        .then();
    } catch (err) {
      console.log(err);
    }
  };

  const [status, setStatus] = React.useState("");
  const [tx_ref, setTx_ref] = React.useState("");

  const confirmPayment = async () => {
    try {
      await axios
        .post(`${API_URL}api/download/`, {
          tx_ref,
          record,
        })
        .then();
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (status == "successful") {
      confirmPayment();
      setSuccessful(true);
      setOpenPayments(false);
      setOpen(false);
    }
  }, [status]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ maxHeight: "95vh" }}>
      <Stack spacing={0.5} sx={matches ? { maxWidth: "90vw" } : {}}>
        <Typography level="h2" sx={{ textAlign: "center" }}>
          COPY VALIDATOR
        </Typography>
        <Typography level="body-md" sx={{ textAlign: "center" }}>
          Pay $1 or more. Select your choice of currency to proceed.
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            savePayment().then(
              handleFlutterPayment({
                callback: (response) => {
                  console.log(response.status, response.tx_ref);
                  setStatus(response.status);
                  setTx_ref(response.tx_ref);
                  closePaymentModal(); // this will close the modal programmatically
                },
                onClose: () => {},
              })
            );
          }}
        >
          <Stack spacing={3}>
            <FormControl required error={values.amount < minimumAmount}>
              <FormLabel>Enter amount</FormLabel>
              <Input
                size="lg"
                endDecorator={values.currency}
                onChange={handleChange("amount")}
                value={values.amount}
                startDecorator={
                  <Select
                    required
                    placeholder="Currency"
                    variant="plain"
                    value={values.currency}
                    onChange={(_, value) => {
                      setValues({ ...values, currency: value });
                    }}
                    slotProps={{
                      listbox: {
                        variant: "outlined",
                      },
                    }}
                    sx={{ "&:hover": { bgcolor: "transparent" } }}
                  >
                    {currencies.map((currency, i) => (
                      <Option key={i} value={currency.code}>
                        {currency.name}
                      </Option>
                    ))}
                  </Select>
                }
              />
              {values.amount < minimumAmount && (
                <FormHelperText>
                  Amount is below minimum: {minimumAmount} {values.currency}(1
                  USD)
                </FormHelperText>
              )}
            </FormControl>

            <Box
              sx={
                matches
                  ? { display: "flex", flexDirection: "column", gap: "1rem" }
                  : {
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "2rem",
                      alignItems: "flex-end",
                    }
              }
            >
              <FormControl required>
                <FormLabel>Your Name</FormLabel>
                <Input
                  size="lg"
                  placeholder="First Name"
                  onChange={handleChange("firstName")}
                />
              </FormControl>
              <FormControl required>
                <FormLabel></FormLabel>
                <Input
                  size="lg"
                  placeholder="Last Name"
                  onChange={handleChange("lastName")}
                />
              </FormControl>
            </Box>

            <FormControl
              required
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <FormLabel>Enter a Valid Email to Receive the file</FormLabel>
              <Input
                size="lg"
                placeholder=""
                onChange={handleChange("email")}
              />
            </FormControl>
            <FormControl
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <FormLabel>
                Enter a Refferal Code to Reward your Affiliate with a 50%
                Commission(Optional)
              </FormLabel>
              <Input
                size="lg"
                placeholder=""
                onChange={handleChange("comment")}
              />
            </FormControl>
            <Button type="submit" disabled={values.amount < minimumAmount}>
              Pay
            </Button>
          </Stack>
        </form>
        <br />
        <img src={poweredBy} style={{ maxHeight: "1rem", width: "auto" }} />

        <Typography level="body-md" sx={{ textAlign: "center" }}>
          If you have any questions, contact copyvalidator@gmail.com
        </Typography>
      </Stack>
    </Box>
  );
}

const currencies = [
  { code: "AED", name: "United Arab Emirates Dirham" },
  { code: "ARS", name: "Argentine Peso" },
  { code: "AUD", name: "Australian Dollar" },
  { code: "CAD", name: "Canadian Dollar" },
  { code: "CHF", name: "Swiss Franc" },
  { code: "CZK", name: "Czech Koruna" },
  { code: "ETB", name: "Ethiopian Birr" },
  { code: "EUR", name: "Euro" },
  { code: "GBP", name: "British Pound Sterling" },
  { code: "GHS", name: "Ghanaian Cedi" },
  { code: "ILS", name: "Israeli New Shekel" },
  { code: "INR", name: "Indian Rupee" },
  { code: "JPY", name: "Japanese Yen" },
  { code: "KES", name: "Kenyan Shilling" },
  { code: "MAD", name: "Moroccan Dirham" },
  { code: "MUR", name: "Mauritian Rupee" },
  { code: "MYR", name: "Malaysian Ringgit" },
  { code: "NGN", name: "Nigerian Naira" },
  { code: "NOK", name: "Norwegian Krone" },
  { code: "NZD", name: "New Zealand Dollar" },
  { code: "PEN", name: "Peruvian Nuevo Sol" },
  { code: "PLN", name: "Polish Zloty" },
  { code: "RUB", name: "Russian Ruble" },
  { code: "RWF", name: "Rwandan Franc" },
  { code: "SAR", name: "Saudi Riyal" },
  { code: "SEK", name: "Swedish Krona" },
  { code: "SGD", name: "Singapore Dollar" },
  { code: "SLL", name: "Sierra Leonean Leone" },
  { code: "TZS", name: "Tanzanian Shilling" },
  { code: "UGX", name: "Ugandan Shilling" },
  { code: "USD", name: "United States Dollar" },
  { code: "XAF", name: "Central African CFA Franc" },
  { code: "XOF", name: "West African CFA Franc" },
  { code: "ZAR", name: "South African Rand" },
  { code: "ZMK", name: "Zambian Kwacha (obsolete)" },
  { code: "ZMW", name: "Zambian Kwacha" },
  { code: "MWK", name: "Malawian Kwacha" },
];
