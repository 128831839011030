import {
	DashboardRounded,
	Login,
	Logout,
	ManageAccounts,
	MenuRounded,
	Person
} from "@mui/icons-material";
import {
	Dropdown,
	Menu,
	MenuButton,
	MenuItem,
	Tooltip
} from "@mui/joy";
import { ListItemIcon, ListItemText } from "@mui/material";

import * as React from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

export default function DropdownMenu() {
  let { user, logoutUser } = React.useContext(AuthContext);
  const navigate = useNavigate();
  //let classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpenChange = React.useCallback((event, isOpen) => {
    setOpen(isOpen);
  }, []);

  return (
    <React.Fragment>
      <Dropdown open={open} onOpenChange={handleOpenChange}>
        <MenuButton sx={{border:"none"}}>
          <Tooltip title="More">
            
              {user.username === "guest" ? (
                <Person sx={{ color: "white", fontSize: 50 }} />
              ) : (
                <MenuRounded
                  font="inherit"
                  sx={{ color: "white", fontSize: 50 }}
                />
              )}
          </Tooltip>
        </MenuButton>
        <Menu>
          {user.username === "guest" ? (
            <div>
              <MenuItem onClick={() => navigate("/login")}>
                <ListItemIcon>
                  <Login fontSize="small" />
                </ListItemIcon>
                <ListItemText>Login</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => navigate("/create-account")}>
                <ListItemIcon>
                  <ManageAccounts fontSize="small" />
                </ListItemIcon>
                <ListItemText>Sign Up</ListItemText>
              </MenuItem>
            </div>
          ) : (
            <>
              {location.pathname !== "/account" && (
                <MenuItem onClick={() => navigate("/dashboard")}>
                  <ListItemIcon>
                    <DashboardRounded color="primary" fontSize="small" />
                  </ListItemIcon>
                  Dashboard
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  logoutUser();
                  window.location.reload();
                }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </>
          )}
        </Menu>
      </Dropdown>
    </React.Fragment>
  );
}
