import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import ChairPage from "../components/StaffPage/Chair/ChairPage";
import StaffPage from "../components/StaffPage/staff/StaffPage";

const FileActionPage = () => {
  let { user, logoutUser } = useContext(AuthContext);
  

  return user.is_superuser == true && user.is_staff == true ? (
    <ChairPage />
  ) : user.is_staff == true && user.is_superuser == false ? (
    <StaffPage />
  ) : (
    <></>
  );
};

export default FileActionPage;
