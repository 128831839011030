import { Chip, Sheet } from "@mui/joy";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";

const StaffRecordsList = ({
  records,
  fetchRecords,
  setSelectedRow,
  selectedRow,
}) => {
  const columns = [
    { field: "document_number", headerName: "Doc No.", width: 130 },
    { field: "document_type", headerName: "Type", width: 100 },
    { field: "owners_name", headerName: "Owner", width: 150 },
    { field: "country", headerName: "Country", width: 100 },
    { field: "institution", headerName: "Institution", width: 100 },
    { field: "issuing_body", headerName: "Issuer", width: 150 },
    {
      field: "verified",
      headerName: "Verified",
      width: 100,
      renderCell: (params) => {
        return params.row.verified == "Verified" ? (
          <Chip color="success" disabled={false} variant="soft">
            Verified
          </Chip>
        ) : params.row.verified == "Pending" ? (
          <Chip color="primary" disabled={false} variant="soft">
            Pending
          </Chip>
        ) : (
          <Chip color="danger" disabled={false} variant="soft">
            Rejected
          </Chip>
        );
      },
    },
    { field: "serial_no", headerName: "Serial", width: 170 },
  ];


  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };

  return (
    <Sheet
      sx={{ height: 600, width: "100%", mt: 5 }}
      color="primary"
      variant="soft"
    >
      {records.length > 0 && (
        <DataGrid
          onRowClick={handleRowClick}
          getRowId={(records) => records.document_number}
          columns={columns}
          rows={records}
          slots={{ toolbar: GridToolbar, showQuickFilter: true }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      )}
  
    </Sheet>
  );
};
export default StaffRecordsList;
