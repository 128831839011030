import {
  ApprovalRounded,
  DocumentScannerRounded,
  HourglassTopTwoTone,
  PendingActionsRounded,
  ReceiptLongRounded,
  RuleFolderTwoTone,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  Link,
  Sheet,
  Typography,
} from "@mui/joy";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import APIContext from "../../../context/APIContext";
import AuthContext from "../../../context/AuthContext";
import PendingApproval from "./PendingApproval";
import RecordsList from "./RecordsList";
import SidePanel from "./SidePanel";
import Unassigned from "./Unassigned";
import UnderScrutiny from "./UnderScrutiny";
import SelectedRecord from "../staff/SelectedRecord";

const ChairPage = () => {
  const [records, setRecords] = useState([]);

  let { API_URL } = useContext(APIContext);
  let { authTokens } = useContext(AuthContext);

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + String(authTokens.access),
    },
  };

  const fetchRecords = async () => {
    await axios
      .get(`${API_URL}api/upload/`, axiosConfig)
      .then((response) => {
        setRecords(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [fileScrutiny, setFileScrutiny] = useState([]);

  const fetchFileScrutiny = async () => {
    await axios
      .get(`${API_URL}api/scrutiny/`, axiosConfig)
      .then((response) => {
        setFileScrutiny(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [downloads, setDownloads] = useState({});

  const fetchDownloads = async () => {
    await axios
      .get(`${API_URL}api/download/`, axiosConfig)
      .then((response) => {
        setDownloads(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchRecords();
    fetchFileScrutiny();
    fetchDownloads();
  }, []);

  const [underScrutiny, setUnderScrutiny] = useState({});
  const [newSubmission, setnewSubmission] = useState({});
  const [verified, setVerified] = useState({});
  const [rejected, setRejected] = useState({});
  const [Accepted, setAccepted] = useState({});

  useEffect(() => {
    setVerified(records.filter((files) => files.verified === "Verified"));
    setRejected(records.filter((files) => files.verified === "Rejected"));
    setUnderScrutiny(
      fileScrutiny.filter(
        (files) => files.status === "Assigned" && files.done === false
      )
    );
    setnewSubmission(
      fileScrutiny.filter((files) => files.status === "Unassigned")
    );
    setAccepted(
      fileScrutiny.filter(
        (files) => files.verified === "Accepted" && files.done === false
      )
    );
  }, [fileScrutiny]);

  const [activeGrid, setActiveGrid] = useState("allRecords");

  return (
    <Sheet
      sx={{
        minHeight: "100vh",
        width: "100vw",
        pt: 15,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          "--Grid-borderWidth": "1px",
          "& > div": {
            borderRight: "var(--Grid-borderWidth) solid",
            borderColor: "divider",
          },
          pl: 5,
          pr: 5,
          pb: 5,
        }}
      >
        <Grid md={3}>
          <Box sx={{ maxHeight: "80vh", overflowX: "hidden" }}>
            <SidePanel
              accepted={Accepted}
              rejected={rejected}
              records={fileScrutiny.filter(
                (files) => files.status !== "Unassigned"
              )}
            />
          </Box>
        </Grid>
        <Grid md={9}>

        {/* Total Number of Files */}
          <Grid xs={12} md={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pr: 5,
                alignItems: "center",
              }}
            >
              <Typography
                level="h2"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={() => setActiveGrid("allRecords")}
              >
                {records?.length} Total Files
              </Typography>
              <Breadcrumbs separator=">" size="lg" sx={{ pl: 1 }}>
                <Link color="primary" href="/">
                  Copy Validator
                </Link>
                <Link color="neutral">Dashboard & Reports</Link>
              </Breadcrumbs>
            </Box>

            <br />
          </Grid>


          <Grid container spacing={4} sx={{display:"flex", justifyContent:"space-between"}}>
            <Grid md={4} lg={4} sm={6}>
              <Card
                onClick={() => setActiveGrid("Verified")}
                color="primary"
                invertedColors
                orientation="horizontal"
                size="sm"
                variant="solid"
                justify="center"
                sx={{
                  width: "260px",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "md",
                    borderColor: "neutral.outlinedHoverBorder",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <ApprovalRounded />
                </Box>
                <CardContent>
                  <Typography level="title-sm">
                    Documents Verified
                  </Typography>
                  <Typography level="body-sm" mb={1}>
                    {verified?.length} Files Can be Searched and Found
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid md={4} lg={4} sm={6}>
              <Card
                onClick={() => setActiveGrid("underScrutiny")}
                color="primary"
                invertedColors
                orientation="horizontal"
                size="sm"
                variant="solid"
                justify="center"
                sx={{
                  width: "260px",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "md",
                    borderColor: "neutral.outlinedHoverBorder",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <HourglassTopTwoTone />
                </Box>
                <CardContent>
                  <Typography level="title-sm">
                    Documents Under Scrutiny
                  </Typography>
                  <Typography level="body-sm" mb={1}>
                    {underScrutiny?.length} Documents are under review
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid md={4} lg={4} sm={6}>
              <Card
                onClick={() => setActiveGrid("Rejected")}
                color="primary"
                invertedColors
                orientation="horizontal"
                size="sm"
                variant="solid"
                justify="center"
                sx={{
                  width: "260px",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "md",
                    borderColor: "neutral.outlinedHoverBorder",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <RuleFolderTwoTone />
                </Box>
                <CardContent>
                  <Typography level="title-sm">
                    Documents Rejected
                  </Typography>
                  <Typography level="body-sm" mb={1}>
                    {rejected?.length} Files did not qualify to be Verified
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid md={4} lg={4} sm={6}>
              <Card
                onClick={() => setActiveGrid("pendingApproval")}
                color="primary"
                invertedColors
                orientation="horizontal"
                size="sm"
                variant="solid"
                justify="center"
                sx={{
                  width: "260px",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "md",
                    borderColor: "neutral.outlinedHoverBorder",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <PendingActionsRounded />
                </Box>
                <CardContent>
                  <Typography level="title-sm">
                    Reccomended for Approval
                  </Typography>
                  <Typography level="body-sm" mb={1}>
                    {Accepted?.length} Files pending for final approval
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid md={4} lg={4} sm={6}>
              <Card
                onClick={() => setActiveGrid("unassigned")}
                color="primary"
                invertedColors
                orientation="horizontal"
                size="sm"
                variant="solid"
                justify="center"
                sx={{
                  width: "260px",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "md",
                    borderColor: "neutral.outlinedHoverBorder",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <DocumentScannerRounded />
                </Box>
                <CardContent>
                  <Typography level="title-sm">
                    New Document Submissions
                  </Typography>
                  <Typography level="body-sm" mb={1}>
                    {newSubmission?.length} Files queed to assign for
                    scrutiny
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid md={4} lg={4} sm={6}>
              <Card
                color="primary"
                invertedColors
                orientation="horizontal"
                size="sm"
                variant="solid"
                justify="center"
                sx={{
                  width: "260px",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "md",
                    borderColor: "neutral.outlinedHoverBorder",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <ReceiptLongRounded />
                </Box>
                <CardContent>
                  <Typography level="title-sm">
                    Total Downloads
                  </Typography>
                  <Typography level="body-sm" mb={1}>
                    {downloads?.length} Successful file Downloads
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {activeGrid === "allRecords" && <RecordsList records={records} />}
          {activeGrid === "Verified" && <RecordsList records={verified} />}
          {activeGrid === "Rejected" && <RecordsList records={rejected} />}
          {activeGrid === "underScrutiny" && (
            <UnderScrutiny records={underScrutiny?.map((obj) => obj.record)} />
          )}
          {activeGrid === "pendingApproval" && (
            <PendingApproval
              records={Accepted}
              fetchRecords={fetchRecords}
              fetchFileScrutiny={fetchFileScrutiny}
            />
          )}

          {activeGrid === "unassigned" && (
            <Unassigned
              records={newSubmission}
              fetchRecords={fetchRecords}
              fetchFileScrutiny={fetchFileScrutiny}          
            />
          )}
        </Grid>
        {/* <Box>
                <AllocationForm />
              </Box> */}
      </Grid>
    </Sheet>
  );
};

export default ChairPage;
