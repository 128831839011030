import * as React from "react";
import {
  DataGrid,
  GridToolbar,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { Box, Button, Chip, Sheet, Typography } from "@mui/joy";
import { GridToolbarExport } from "@mui/x-data-grid";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { GridToolbarColumnsButton } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import APIContext from "../../../context/APIContext";
import AuthContext from "../../../context/AuthContext";
import SelectedRecordModal from "./SelectedRecordModal";

const PendingApproval = ({ records, fetchRecords, fetchFileScrutiny }) => {
  const [arrayOfRecords, setArrayOfRecords] = React.useState({});

  React.useEffect(() => {
    if (records.length > 0) {
      setArrayOfRecords(records.map((obj) => obj.record));
    }
  }, [records]);

  const columns = [
    { field: "document_number", headerName: "Doc No.", width: 130 },
    { field: "document_type", headerName: "Type", width: 120 },
    { field: "owners_name", headerName: "Owner", width: 150 },
    { field: "country", headerName: "Country", width: 100 },
    { field: "institution", headerName: "Institution", width: 100 },
    { field: "issuing_body", headerName: "Issuer", width: 150 },
    {
      field: "verified",
      headerName: "Verified",
      width: 100,
      renderCell: (params) => {
        return params.row.verified == "Verified" ? (
          <Chip color="success" disabled={false} variant="soft">
            Verified
          </Chip>
        ) : params.row.verified == "Pending" ? (
          <Chip color="primary" disabled={false} variant="soft">
            Pending
          </Chip>
        ) : (
          <Chip color="Rejected" disabled={false} variant="soft">
            Rejected
          </Chip>
        );
      },
    },
    { field: "serial_no", headerName: "Serial", width: 170 },
  ];

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  let { API_URL } = React.useContext(APIContext);
  let { authTokens } = React.useContext(AuthContext);

  const handleApprove = async () => {
    // Make a POST request using the Fetch API
    await fetch(`${API_URL}api/upload/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + String(authTokens.access),
      },
      body: JSON.stringify({
        records: rowSelectionModel,
      }),
    })
      .then((response) => {
        fetchRecords();
        fetchFileScrutiny();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setOpenModal(true);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />

        <GridToolbarExport
          slotprops={{
            tooltip: { title: "Export data" },
            button: { variant: "outlined" },
          }}
        />
        <Box sx={{ flexGrow: 1 }} />

        <Button color="success" onClick={() => handleApprove()}>
          Approve
        </Button>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  return (
    <Sheet
    sx={{ height: 600, width: "100%", mt: 5 }}
    color="primary"
    variant="soft"
    >
      <DataGrid
        density="comfortable"
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        onRowClick={handleRowClick}
        getRowId={(arrayOfRecords) => arrayOfRecords.document_number}
        columns={columns}
        rows={arrayOfRecords}
        slots={{
          toolbar: CustomToolbar,
          showQuickFilter: true,
        }}
      />
      <SelectedRecordModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        selectedRow={selectedRow}
      />
    </Sheet>
  );
};
export default PendingApproval;
